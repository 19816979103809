import { RouteConfig } from 'vue-router'
import { metaRequireAuth } from '@/router/meta-constants'
import HomePage from '@/views/home/index.vue'
import ScanTicketPage from '@/views/scan-ticket/index.vue'
import ReportPage from '@/views/report/index.vue'
import AboutPage from '@/views/about/index.vue'
import PrintPage from '@/views/print/index.vue'
import ChangePinPage from '@/views/change-pin/index.vue'
import LotoPage from '@/views/loto/index.vue'
import LotoPageEvents from '@/views/loto/events.vue'
import LotoPageNumber from '@/views/loto/number.vue'
import LotoPage645 from '@/views/loto-645/index.vue'
import LotoPage645Number from '@/views/loto-645/numbers.vue'
import LotoPage459 from '@/views/loto-459/index.vue'
import LotoPage459Number from '@/views/loto-459/numbers.vue'
import LotoPage545 from '@/views/loto-545/index.vue'
import LotoPage545Number from '@/views/loto-545/numbers.vue'
import LotoPageInstantNumber from '@/views/lotto-instant/numbers.vue'
import ResultPage from '@/views/result/index.vue'
import PromotionResultsPage from '@/views/promotion-results/index.vue'
import SportPage from '@/views/sport/index.vue'
import HistoryPage from '@/views/history/index.vue'
import VoucherPage from '@/views/voucher/index.vue'

const authenticatedRoutes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home Page',
        meta: metaRequireAuth,
        component: HomePage,
    },
    {
        path: '/scan-ticket',
        name: 'Ticket Detail Page',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: ScanTicketPage,
    },
    {
        path: '/report-final',
        name: 'Report Final Page',
        component: ReportPage,
        meta: {
            ...metaRequireAuth,
            isFinal: true,
            isShowMenu: true,
        },
    },
    {
        path: '/report',
        name: 'Report Page',
        component: ReportPage,
        meta: {
            ...metaRequireAuth,
            isFinal: false,
            isShowMenu: true,
        },
    },
    {
        path: '/about',
        name: 'About',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: AboutPage,
    },
    {
        path: '/print',
        name: 'Print Test Page',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: PrintPage,
    },
    {
        path: '/change-pin',
        name: 'ChangePin',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: ChangePinPage,
    },
    {
        path: '/loto',
        name: 'Loto',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: LotoPage,
    },
    {
        path: '/loto-events',
        name: 'Loto Events',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: LotoPageEvents,
    },
    {
        path: '/loto-number',
        name: 'Loto number',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: LotoPageNumber,
    },
    {
        path: '/loto-645',
        name: 'Loto 6/45',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: LotoPage645,
    },
    {
        path: '/loto-645/numbers',
        name: 'Loto 6/45 numbers',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: LotoPage645Number,
    },
    {
        path: '/loto-459',
        name: 'Loto 4/59',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: LotoPage459,
    },
    {
        path: '/loto-459/numbers',
        name: 'Loto 4/59 numbers',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: LotoPage459Number,
    },
    {
        path: '/loto-545',
        name: 'Loto 5/45',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: LotoPage545,
    },
    {
        path: '/loto-545/numbers',
        name: 'Loto 5/45 numbers',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: LotoPage545Number,
    },
    {
        path: '/lotto-instant/numbers',
        name: 'Loto instant numbers',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: LotoPageInstantNumber,
    },
    {
        path: '/results',
        name: 'Result Page',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: ResultPage,
    },
    {
        path: '/promotion-results',
        name: 'Promotion Results Page',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: PromotionResultsPage,
    },
    {
        path: '/sports',
        name: 'Sport Pages',
        component: SportPage,
        meta: {
            ...metaRequireAuth,
            isNotPadding: true,
        },
    },
    {
        path: '/history',
        name: 'History Page',
        meta: {
            ...metaRequireAuth,
            isShowMenu: true,
        },
        component: HistoryPage,
    },
    {
        path: '/voucher',
        name: 'Voucher Page',
        meta: {
            ...metaRequireAuth,
            isNotPadding: true,
            isShowMenu: true,
        },
        component: VoucherPage,
    },
]

export default authenticatedRoutes
