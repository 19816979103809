<template>
    <div v-if="!isLoadingPage" class="screen-number container-screen">
        <div @click="onClickBack" class="loto__back">
            <img src="~ThemePath/images/chevron-left-green.png" />
            <div class="loto__back__title">
                {{ getTranslatedText("back") }}
            </div>
        </div>
        <div class="loto__title">{{ getTranslatedText(dataSource.title) }}</div>
        <div class="screen-number__box-btn-quick-pick">
            <ButtonCommon :isNoHover="true" value="Quick Pick" className="screen-number__btn-quick-pick" nameFunction="on-quick-pick-number" />
        </div>
        <div class="screen-number__list">
            <div class="screen-number__item" :class="{ active: selection.includes(value.number) }" v-for="(value, index) in listNumber" :key="index" @click="() => onSelectNumber(value.number)">
                {{ value.number }}
            </div>
        </div>
        <div class="screen-number__value-number">
            <input autocomplete="off" @keyup="removeDotDash" v-model="valueSelection" class="screen-number__input" type="text" @blur="onBlurValueSelection" pattern="[0-9]*" inputmode="numeric" ref="inputValueNumber" @keyup.enter="hideKeypad('inputValueNumber')" />
        </div>
        <div class="screen-number__footer">
            <div class="screen-number__footer-action-price">
                <div class="screen-number__info">
                    <span class="screen-number__text bold">{{ getTranslatedText(dataSource.title) }}</span>
                    <span class="screen-number__text">{{ renderPrice(this.propsDataSource.isPariMutuelLotto ? priceTicket640 : dataSource.wager, 2) }}</span>
                </div>
                <input disabled="disabled" v-model="priceNumber" class="screen-number__input price" step="1" min="0" max="100" pattern="[0-9]*" inputmode="numeric" @blur="onBlurPriceNumber" v-on:keypress="onlyNumber" ref="inputPrice" @keyup.enter="hideKeypad('inputPrice')" />
            </div>
            <div class="screen-number__footer-action">
                <div class="screen-number__info">
                    <span class="screen-number__text bold">{{ getTranslatedText("total") }}</span>
                    <span class="screen-number__text bold">{{ renderPrice(totalNumber, 2) }}</span>
                </div>
                <div class="screen-number__box-button">
                    <ButtonCommon :isNoHover="true" :isLoading="$store.state.isLoadingAddCart" value="clear" nameFunction="on-show-dialog-clear" />
                    <ButtonCommon :isNoHover="true" :isLoading="$store.state.isLoadingAddCart" type="error" value="add to cart" nameFunction="on-add-to-cart" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import languageMixins from "@/mixins/language/language-mixins";
import EventBus from "@/utils/EventBus";
import ButtonCommon from "@/components/common/button/index.vue";
import LoadingScreen from "@/components/common/loading/index.vue";
import TitleCommon from "@/components/common/title/index.vue";
import { formatPrice, removeDotDash, generateCombinations, isStrEmpty } from "@/utils/utils";

const WARGER_MIN_ENV = process.env.VUE_APP_WARGER_MIN || 2000;
const WARGER_MAX_ENV = process.env.VUE_APP_WARGER_MAX || 50000;

export default {
    mixins: [languageMixins],
    components: { ButtonCommon, LoadingScreen, TitleCommon },
    data() {
        return {
            isLoadingPage: true,
            dataSource: null,
            propsDataSource: null,
            listNumber: [],
            selection: [],
            valueSelection: "",
            priceNumber: 0,
            priceTicket640: 0,
            totalNumber: 0,
            defaultWager: 0,
            WARGER_MIN_ENV,
            WARGER_MAX_ENV,
        };
    },
    mounted() {
        this.fetchData();
    },
    created() {
        EventBus.$on("on-change-price-number", (param) => this.onChangePriceNumber(param));
        EventBus.$on("on-quick-pick-number", () => this.onClickQuickPick());
        EventBus.$on("on-clear-select-number", () => this.onClearSelectNumber());
        EventBus.$on("on-show-dialog-clear", () => this.onShowConfirmClear());
        EventBus.$on("on-add-to-cart", () => this.onAddToCart());
    },
    watch: {
        selection() {
            this.renderValueNumber();
        },
        priceNumber() {
            this.totalNumber = parseInt(this.priceNumber.replaceAll(",", "")) * this.propsDataSource.event_id.length;
            this.dataSource.wager = parseInt(this.priceNumber.replaceAll(",", ""));
        },
    },
    destroyed() {
        EventBus.$off("on-change-price-number");
        EventBus.$off("on-quick-pick-number");
        EventBus.$off("on-clear-select-number");
        EventBus.$off("on-show-dialog-clear");
        EventBus.$off("on-add-to-cart");
    },
    methods: {
        fetchData() {
            let loto459Events = localStorage.getItem("loto459-event");
            if (isStrEmpty(loto459Events)) {
                this.$router.push("/loto-459");
            }
            loto459Events = JSON.parse(loto459Events);
            this.dataSource = loto459Events;
            this.propsDataSource = loto459Events;
            this.priceNumber = this.renderPrice(parseInt(this.dataSource.wager));
            this.defaultWager = parseInt(this.dataSource.wager);
            this.priceTicket640 = parseInt(loto459Events.wager);
            this.listNumber = Array.from(Array(59), (_, i) => {
                return { number: ++i, selected: false };
            });
            this.isLoadingPage = false;
        },

        removeDotDash (e) {
            this.valueSelection = removeDotDash(this.valueSelection)
        },

        renderTextTickets(arr) {
            let valueNumberSelectRender = "";
            arr.forEach((x, index) => {
                valueNumberSelectRender = `${valueNumberSelectRender}${index !== 0 ? "," : ""} ${x}`;
            });
            return valueNumberSelectRender;
        },

        onClickBack() {
            this.$router.push("/loto-459");
            localStorage.removeItem("loto459-event");
        },

        // action screen
        onClickQuickPick() {
            let numbers = Array(59)
                .fill(0)
                .map((_, index) => index + 1);
            numbers.sort(() => Math.random() - 0.5);
            numbers = [...numbers.slice(0, this.dataSource.minPicks)];
            this.selection = [...numbers];
        },

        onShowConfirmClear() {
            const param = {
                description: "Are you sure you want to delete?",
                nameFunction: "on-clear-select-number",
            };
            EventBus.$emit("on-show-dialog", param);
        },

        onClearSelectNumber() {
            this.priceNumber = this.renderPrice(parseInt(this.defaultWager));
            this.selection = [];
        },

        onAddToCart() {
            this.$store.state.isLoadingAddCart = true;
            if (this.dataSource.minPicks > this.selection.length) {
                const param = {
                    text: this.getTranslatedText(`Please make sure that you select [number] of balls!`, {
                        number: this.dataSource.minPicks,
                    }),
                    type: "warning",
                };
                EventBus.$emit("show-notications", param);
                this.$store.state.isLoadingAddCart = false;

                return false;
            }
            const param = this.dataSource;
            param["title"] = this.dataSource.title;
            param["selection"] = this.selection;
            param["date"] = this.selection;
            param["numberQp"] = this.dataSource.minPicks;
            param["wager"] = parseInt(this.priceNumber.replaceAll(",", ""));
            param["totalCart"] = this.totalNumber;
            param["id"] = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g, "");
            this.$store.dispatch("onAddToCart", param);
            this.onClearSelectNumber();
            localStorage.removeItem("loto459-event");
        },

        // select number
        renderValueNumber() {
            const selection = [...this.selection];
            const numberSelect = selection.sort((a, b) => a - b);
            let numberQp = parseInt(this.dataSource.numberQp);
            if (selection.length > numberQp) {
                let combinations = generateCombinations(selection.length, numberQp);
                let priceTicket640 = parseFloat(this.priceNumber.replaceAll(",", "")) * combinations;
                this.priceTicket640 = priceTicket640;
                this.totalNumber = priceTicket640 * this.propsDataSource.event_id.length;
            } else {
                let priceTicket640 = parseInt(this.propsDataSource.wager);
                this.priceTicket640 = priceTicket640;
                this.totalNumber = priceTicket640 * this.propsDataSource.event_id.length;
            }

            let valueNumberSelectClone = this.renderTextTickets(numberSelect);

            this.valueSelection = valueNumberSelectClone;
        },

        onSelectNumber(number) {
            const selection = this.selection;
            const index = selection.findIndex((x) => x === number);
            if (index !== -1) {
                selection.splice(index, 1);
            } else {
                if (this.dataSource.maxPicks > this.selection.length) {
                    selection.push(number);
                }
            }

            this.selection = [...selection];
        },

        onBlurValueSelection() {
            let inputArray = this.valueSelection
                .split(",")
                .map((num) => Number(num.trim()))
                .filter((num, index, self) => num >= 1 && num <= 59 && index === self.indexOf(num));

            inputArray = inputArray.slice(0, this.propsDataSource.maxPicks);
            this.valueSelection = inputArray.join(",");
            this.selection = [...inputArray];
        },

        // change price
        onBlurPriceNumber() {
            let price = !isStrEmpty(this.priceNumber) ? this.priceNumber.replaceAll(",", "") : 0;
            let value = parseFloat(price) || 0;
            if (value < this.WARGER_MIN_ENV) value = this.WARGER_MIN_ENV;
            if (value > this.WARGER_MAX_ENV) value = this.WARGER_MAX_ENV;
            this.priceNumber = this.renderPrice(value);
        },

        onChangePriceNumber(value) {
            let price = value || 0;
            let priceMore = parseInt(this.priceNumber.replaceAll(",", "")) + price;
            if (priceMore > WARGER_MAX_ENV) priceMore = WARGER_MAX_ENV;
            this.priceNumber = this.renderPrice(priceMore);
        },

        onlyNumber(event) {
            if (event.key === "-" && event.target.selectionStart !== 0) {
                event.preventDefault();
                return;
            }
            if (event.keyCode < 48 || event.keyCode > 57) {
                event.preventDefault();
            }
        },

        // uti render
        renderPrice(price, decimal = 0) {
            return formatPrice(price, decimal);
        },

        hideKeypad(ref) {
            this.$refs[ref].blur();
        },
    },
};
</script>

<style lang="scss">
@import "~ThemePath/scss/games/_number.scss";
</style>
