import axios from 'axios'
import BaseService from './base.service'
import { getTokenV3 } from './axios'
import store from '@/store/index'

const API_URL = process.env.VUE_APP_API_URL
const EXTERNAL_ID_TYPE = process.env.VUE_APP_EXTERNAL_ID_TYPE
const SETTING_JSON = process.env.VUE_APP_SETTING_JSON || ''

let userInfo: any = store.state.userInfo
interface reBetSportsInterface {
    url: string
    code: string
    betId: string
    token: string
}
class BetslipsService extends BaseService {
    async searchBetslip(id: string) {
        const settings = JSON.parse(SETTING_JSON)
        return await this.get(`${settings.url}/api/retail/betslip/detail/${id}`).then(({ data }) => data)
    }

    async checkRebetLoto(id: string) {
        const settings = JSON.parse(SETTING_JSON)
        return await this.get(`${settings.url}/api/retail/betslip/detail-for-rebet/L-${id}`).then(({ data }) => data)
    }

    async reBetTickets(reference: string) {
        const payload = {
            account: store.state.userInfo.gamev3.account,
            currency: store.state.userInfo.gamev3.currency_code,
            reference: reference,
            client_token: store.state.tokenV3 !== null ? store.state.tokenV3 : getTokenV3(),
        }

        let response = await this.postAuth2(`${API_URL}/api/multi-lotto/wager`, payload)

        return response.data
    }

    async reBetTicket645(externalId: string) {
        const payload = {
            account: store.state.userInfo.gamev3.account,
            currency: store.state.userInfo.gamev3.currency_code,
            externalId: externalId,
            client_token: store.state.tokenV3 !== null ? store.state.tokenV3 : getTokenV3(),
        }

        let response = await this.postAuth2(`${API_URL}/api/pari-mutuel-lotto/wager`, payload)

        return response.data
    }

    async reBetSports(param: reBetSportsInterface) {
        const { url, code, betId, token } = param

        const axiosTokenlessInstance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        })

        let response = await axiosTokenlessInstance.post(`${url}/sports/rebet?admin_user_id=${userInfo?.details?.id}&code=${code}&ref=${betId.replace('S-', '')}`)

        return response.data
    }

    transactLotto = (entries: any, bet_timestamp, typeGame: string, rebetFromBetslip: string = '') => {
        let payload = {
            account: store.state.userInfo.gamev3.account,
            additionalInformation: {
                admin_user_id: userInfo?.details?.id || '',
                rebet_from_betslip: rebetFromBetslip && rebetFromBetslip.trim() !== '' ? rebetFromBetslip.replace('L-', '') : ''
            },
            currency: store.state.userInfo.gamev3.currency_code,
            retail: 'yes',
            customerID: store.state.userInfo.gamev3.lotto_retail_user,
            client_token: store.state.tokenV3 !== null ? store.state.tokenV3 : getTokenV3(),
            external_id_type: EXTERNAL_ID_TYPE,
            bets: [
                {
                    entries: entries,
                },
            ],
        }

        return this.postAuth2(`${API_URL}/api/${typeGame ? typeGame : 'multi-lotto'}/transact`, payload).then(({ data }) => data)
    }

    async cancelBetslip(betslipId: string) {
        const settings = JSON.parse(SETTING_JSON)

        return await this.get(`${settings.url}/api/retail/betslip/cancel/${betslipId}`).then(({ data }) => data)
    }

    async deviceBindingcheck() {
        const settings = JSON.parse(SETTING_JSON)

        return await this.post(`${settings.url}/api/retail/cashier/device-binding-check`, '').then(({ data }) => data)
    }

    async settleBetslip(id: string, forRaffle: boolean = false) {
        const settings = JSON.parse(SETTING_JSON)
        let sub = ""
        if(forRaffle) sub = "?is_raffle_payment=true";
        return await this.get(`${settings.url}/api/retail/betslip/cashout/${id}${sub}`).then(({ data }) => data)
    }

    async updatePrintingStatus(ticketType:string, reference: string, reprintCount = 0) {
        const settings = JSON.parse(SETTING_JSON)
        let payload = {ticketType, reference, reprintCount, reprint: reprintCount > 0}
        return await this.post(`${settings.url}/api/retail/cashier/update-print-status`, payload).then(({ data }) => data)
    }
}

export default new BetslipsService()
