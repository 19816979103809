<template>
    <div class="transaction">
        <div v-if="!isLoadingScreen">
            <div class="transaction__content" v-for="(item, key) in dataHistory" :key="key">
                <div class="col-first">
                    <p>
                        <b>{{ getTranslatedText('created at') }}:</b> {{ renderDate(item.created_at) }}
                    </p>
                    <p>
                        <b>{{ getTranslatedText('transaction id') }}:</b> {{ item.transaction_id }}
                    </p>
                    <p>
                        <b>{{ getTranslatedText('amount') }}:</b> {{ renderPrice(item.wager) }}
                    </p>
                </div>
                <div class="col-last">
                    <p>
                        <b>{{ getTranslatedText('type') }}:</b> {{ getTranslatedText(item.type) }}
                    </p>
                    <p>
                        <b>{{ getTranslatedText('status') }}:</b> {{ getStatus(item) }}
                    </p>
                    <div class="transaction__box-button">
                        <ButtonCommon
                            v-if="
                                !(item.cancelled || parseInt(item.cancelled_count) > 0) &&
                                item.type !== INSTANT_SLUG_GAME
                            "
                            className="w100"
                            :isNoHover="true"
                            value="CANCEL TICKET"
                            nameFunction="on-click-modal"
                            :paramFunction="item"
                        />
                    </div>
                </div>
            </div>
        </div>
        <EmptyScreen v-if="!isLoadingScreen && dataHistory.length === 0" text="Empty result" />
        <LoadingScreen v-if="isLoadingScreen" />
        <div class="transaction__re-box-button">
            <ButtonCommon
                className="w100"
                :isNoHover="true"
                :isLoading="isLoadingButton"
                value="Refresh"
                nameFunction="on-refresh-history"
            />
        </div>
        <v-dialog v-model="dialog" content-class="modal-ticket">
            <v-card>
                <v-card-text>
                    {{ getTranslatedText('Are you sure you would like to cancel this Bet?') }}
                    <br />
                    {{ getTranslatedText('Identifiant de transaction:') }} {{ transactionId }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="modal-ticket__box-button">
                        <ButtonCommon
                            className="w100 close"
                            :isNoHover="true"
                            value="Close"
                            nameFunction="on-close-modal"
                        />
                        <ButtonCommon
                            className="w100 yes"
                            :isNoHover="true"
                            :isLoading="isLoadingButtonCancel"
                            value="confirm"
                            nameFunction="on-handle-cancel-ticket"
                        />
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import EmptyScreen from '@/components/common/empty/index.vue'
import LoadingScreen from '@/components/common/loading/index.vue'
import EventBus from '@/utils/EventBus'
import HistoryService from '@/services/history.service'
import BetslipsService from '@/services/betslip.service'
import { formatDateUTC00, formatPrice } from '@/utils/utils'
import ButtonCommon from '@/components/common/button/index.vue'
import { isArrayEmpty } from '@/utils/utils'
import { INSTANT_SLUG_GAME } from '@/constants/lotto'

export default {
    mixins: [languageMixins],
    components: { ButtonCommon, EmptyScreen, LoadingScreen },
    props: {
        limit: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            isLoadingButton: false,
            isLoadingScreen: false,
            isLoadingButtonCancel: false,
            dataHistory: [],
            dialog: false,
            transactionId: null,
            betslipId: null,
            INSTANT_SLUG_GAME,
        }
    },
    mounted() {
        this.fetchHistory()
    },
    created() {
        this.fetchHistory()
        EventBus.$on('on-click-modal', (item) => {
            this.dialog = true
            this.transactionId = item.transaction_id
            this.betslipId = item.betslip_id
        })
        EventBus.$on('on-close-modal', () => {
            this.dialog = false
        })
        EventBus.$on('on-handle-cancel-ticket', () => {
            this.handleCancelTicket()
        })
        EventBus.$on('on-refresh-history', () => {
            this.isLoadingButton = true
            this.fetchHistory()
        })
    },
    destroyed() {
        EventBus.$off('on-click-modal')
        EventBus.$off('on-close-modal')
        EventBus.$off('on-handle-cancel-ticket')
        EventBus.$off('on-refresh-history')
    },
    methods: {
        async fetchHistory() {
            this.isLoadingScreen = true
            try {
                const resp = await HistoryService.getLastNBet(this.limit)
                if (resp.status === 200) {
                    this.dataHistory = resp.data
                    this.isLoadingScreen = false
                    this.isLoadingButton = false
                } else {
                    this.isLoadingScreen = false
                    this.isLoadingButton = false
                    const param = {
                        text: resp.message,
                        type: 'error',
                    }
                    EventBus.$emit('show-notications', param)
                }
            } catch (error) {
                console.log(error)
                this.isLoadingScreen = false
                this.isLoadingButton = false
            }
        },

        async handleCancelTicket() {
            this.isLoadingButtonCancel = true
            try {
                const resp = await BetslipsService.cancelBetslip(this.betslipId)
                if (resp.status === 200) {
                    this.fetchHistory()
                    this.dialog = false
                    this.isLoadingButtonCancel = false
                    let message = ''
                    !isArrayEmpty(resp.errors)
                        ? resp.errors.forEach((element) => {
                              message += element.message
                          })
                        : (message = resp.message)
                    const param = {
                        text: message ? message : 'cancel successfully',
                        type: 'success',
                    }
                    EventBus.$emit('show-notications', param)
                } else {
                    this.dialog = false
                    let message = ''
                    !isArrayEmpty(resp.errors)
                        ? resp.errors.forEach((element) => {
                              message += element.message
                          })
                        : (message = resp.message)
                    const param = {
                        text: message,
                        type: 'error',
                        className: 'up-first',
                    }
                    EventBus.$emit('show-notications', param)
                    this.isLoadingButtonCancel = false
                }
            } catch (error) {
                console.log(error)
                this.isLoadingButtonCancel = false
            }
        },

        getStatus(item) {
            if (item.cancelled) {
                return this.getTranslatedText('cancelled')
            } else if (parseInt(item.cancelled_count) > 0) {
                return this.getTranslatedText('cancelling')
            }
            return this.getTranslatedText('OK')
        },

        renderDate(date) {
            const dateFormat = formatDateUTC00(date)

            let valueDate = `${dateFormat.day}/${dateFormat.month}/${dateFormat.year} ${dateFormat.hour}:${dateFormat.minutes}`

            return valueDate
        },

        renderPrice(price) {
            return formatPrice(price)
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/transaction/_index.scss';
</style>
