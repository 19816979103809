<template>
    <div class="scan-ticket-detail__list-table">
        <div class="scan-ticket-detail__table">
            <span class="scan-ticket-detail__table-title">{{ getTranslatedText('ticket') }}</span>
            <table>
                <thead>
                    <tr>
                        <th>{{ getTranslatedText('no') }}</th>
                        <th>{{ getTranslatedText('select') }}</th>
                        <th>{{ getTranslatedText('game') }}</th>
                        <th>{{ getTranslatedText('wager') }}</th>
                        <th>{{ getTranslatedText('winnings') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v, i) in details" :key="i">
                        <td>#{{ i + 1 }}</td>
                        <td v-if="v.game_name === INSTANT_SLUG_GAME">
                            <div class="scan-ticket-detail__table-list-number mb" v-for="(bet, index) in v.bet.bets" :key="index">
                                <span v-if="index > 0">+</span>
                                <div class="scan-ticket-detail__table-item-number" v-for="(number, j) in renderItemBalls(1, bet)" :key="j">
                                    {{ number }}
                                </div>
                            </div>
                            <div class="scan-ticket-detail__table-list-number" v-for="(bet, index) in v.bet.bets" :key="index">
                                <span v-if="index > 0">+</span>
                                <div class="scan-ticket-detail__table-item-number" v-for="(number, j) in renderItemBalls(2, bet)" :key="j">
                                    {{ number }}
                                </div>
                            </div>
                        </td>
                        <td v-else>
                            <div class="scan-ticket-detail__table-list-number" v-for="(bet, index) in v.bet.bets" :key="index">
                                <span v-if="index > 0">+</span>
                                <div class="scan-ticket-detail__table-item-number" v-for="(number, j) in bet" :key="j">
                                    {{ number }}
                                </div>
                            </div>
                        </td>
                        <td>{{ getTranslatedText(v.game_name) }}  </td>
                        <td>{{ renderPrice(v.wager) }} </td>
                        <td :class="[{'scan-ticket-detail__line': v.game_name === INSTANT_SLUG_GAME}]">
                            <span v-if="v.game_name === INSTANT_SLUG_GAME">|</span>
                            {{ v.winnings ? renderPrice(v.winnings) : '0.00' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="scan-ticket-detail__table">
            <span class="scan-ticket-detail__table-title">{{ getTranslatedText('draw') }}</span>
            <div class="scan-ticket-detail__table-item-draw" v-for="(value, index) in events" :key="index">
                <div class="scan-ticket-detail__table-item">
                    <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-label">{{ getTranslatedText('draw date') }}</span>
                    <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-value"> {{ renderDate(value.draw_date) }} </span>
                </div>
                <div class="scan-ticket-detail__table-item">
                    <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-label">{{ getTranslatedText('result') }} </span>
                    <template v-if="value.results && value.results.length > 0">
                        <div class="scan-ticket-detail__table-list-number" v-for="(bet, index) in value.results" :key="index">
                            <template  v-if="bet.length > INSTANT_DETAIL_LIMIT_SHOW_NUMBER_LINE_RESULT">
                                <div class="scan-ticket-detail__table-list-number-box">
                                    <div class="scan-ticket-detail__table-list-number-column">
                                        <div class="scan-ticket-detail__table-item-number" v-for="(number, j) in renderItemBalls(1, bet, INSTANT_DETAIL_LIMIT_SHOW_NUMBER_LINE_RESULT)" :key="j">
                                            {{ number }}
                                        </div>
                                    </div>
                                    <div class="scan-ticket-detail__table-list-number-column">
                                        <div class="scan-ticket-detail__table-item-number" v-for="(number, j) in renderItemBalls(2, bet, INSTANT_DETAIL_LIMIT_SHOW_NUMBER_LINE_RESULT)" :key="j">
                                            {{ number }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <span v-if="index > 0">+</span>
                                <div class="scan-ticket-detail__table-item-number" v-for="(number, j) in bet" :key="j">
                                    {{ number }}
                                </div>
                            </template>
                        </div>
                    </template>
                    <span v-else class="scan-ticket-detail__pending-results">
                        {{ getTranslatedText('pending results') }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { INSTANT_DETAIL_LIMIT_SHOW_NUMBER_LINE_RESULT, INSTANT_SLUG_GAME } from '@/constants/lotto';
import languageMixins from '@/mixins/language/language-mixins'

import { formatDateUTC00, formatPrice, splitDataLottoInstant } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    props: {
        details: {
            type: Array,
            default: () => [],
        },
        events: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            INSTANT_SLUG_GAME,
            INSTANT_DETAIL_LIMIT_SHOW_NUMBER_LINE_RESULT
        }
    },
    methods: {
        renderItemBalls(type, data, number) {
            console.log(data);
            
           return splitDataLottoInstant(type, data, number)
        },

        renderDate(date) {
            const dateFormat = formatDateUTC00(date)

            let valueDate = `${dateFormat.day}/${dateFormat.month}/${dateFormat.year} ${dateFormat.hour}:${dateFormat.minutes}`

            return valueDate
        },

        renderPrice(price) {
            return formatPrice(price)
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/scan-ticket/_detail.scss';
</style>
