<template>
  <div class="about__wrap">
    <div v-if="step === 1" class="about__container">
      <h2 class="about__title">{{ getTranslatedText("About games") }}</h2>
      <ul class="about__list">
        <li class="about__list--item">
          <a @click="handleDetailPage(10)">{{ getTranslatedText("Active promotions") }}</a>
        </li>
        <template v-if="this.$store.state.isShow590">
          <li class="about__list--item">
            <a @click="handleDetailPage(2)">{{ getTranslatedText("Guinne nap") }}</a>
          </li>
          <li class="about__list--item">
            <a @click="handleDetailPage(3)">{{ getTranslatedText("Guinne chance") }}</a>
          </li>
          <li class="about__list--item">
            <a @click="handleDetailPage(4)">{{ getTranslatedText("Guinne turbo") }}</a>
          </li>
          <li class="about__list--item">
            <a @click="handleDetailPage(9)">{{ getTranslatedText("Loto extra") }}</a>
          </li>
        </template>
        <li v-if="this.$store.state.isShow645" class="about__list--item">
          <a @click="handleDetailPage(5)">{{ getTranslatedText("Loto 6/45") }}</a>
        </li>
        <li v-if="this.$store.state.isShow459" class="about__list--item">
          <a @click="handleDetailPage(6)">{{ getTranslatedText("loto 4/59") }}</a>
        </li>
        <li v-if="this.$store.state.isShow545" class="about__list--item">
          <a @click="handleDetailPage(7)">{{ getTranslatedText("loto 5/45") }}</a>
        </li>
        <li v-if="this.$store.state.isShowInstantLotto" class="about__list--item">
          <a @click="handleDetailPage(11)">{{ getTranslatedText(INSTANT_SLUG_GAME) }}</a>
        </li>
        <li class="about__list--item">
          <a @click="handleDetailPage(8)">{{ getTranslatedText("Cancellations rules") }}</a>
        </li>
      </ul>
    </div>
    <div v-if="step === 2" class="about_item">
      <div class="about_item__head">
        {{ getTranslatedText("1 - Loto Nap") }}
      </div>
      <div class="about_item__content" v-for="(lotoNap, index) in transformedData(this.dataLotonap)" :key="index">
        <div class="about_item__content__title">
          {{ getTranslatedText(lotoNap.title) }}
        </div>
        <div class="about_item__content__table">
          <div class="about_item__content__table__item">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">
                    {{ getTranslatedText("nombre de numéros gagnants") }}
                  </th>
                  <th scope="col">{{ getTranslatedText("Mise") }}</th>
                  <th scope="col">{{ getTranslatedText("Gains en GNF") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ getTranslatedText(lotoNap.nombre_gains[0].text) }}</td>
                  <td class="text-transform" :rowspan="lotoNap.nombre_gains.length">
                    {{ renderPrice(lotoNap.price) }} {{ getTranslatedText($store.state.userInfo.gamev3.currency_code) }}
                  </td>
                  <td>
                    {{ renderPrice(lotoNap.nombre_gains[0].price) }}
                  </td>
                </tr>
                <template v-for="(nombre_gains, index) in lotoNap.nombre_gains">
                  <tr v-if="index" :key="index">
                    <td>{{ getTranslatedText(nombre_gains.text) }}</td>
                    <td>{{ renderPrice(nombre_gains.price) }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 3" class="about_item">
      <div class="about_item__head">
        {{ getTranslatedText("2 - Loto Chance") }}
      </div>
      <div class="about_item__content" v-for="(lotoChance, index) in transformedData(this.dataChance)" :key="index">
        <div class="about_item__content__title">
          {{ getTranslatedText(lotoChance.title) }}
        </div>
        <div class="about_item__content__table">
          <div class="about_item__content__table__item">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">
                    {{ getTranslatedText("nombre de numéros gagnants") }}
                  </th>
                  <th scope="col">{{ getTranslatedText("Mise") }}</th>
                  <th scope="col">{{ getTranslatedText("Gains en GNF") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ getTranslatedText(lotoChance.nombre_gains[0].text) }}</td>
                  <td class="text-transform" :rowspan="lotoChance.nombre_gains.length">
                    {{ renderPrice(lotoChance.price) }} {{ getTranslatedText($store.state.userInfo.gamev3.currency_code)
                    }}
                  </td>
                  <td>
                    {{ renderPrice(lotoChance.nombre_gains[0].price) }}
                  </td>
                </tr>
                <template v-for="(nombre_gains, index) in lotoChance.nombre_gains">
                  <tr v-if="index" :key="index">
                    <td>{{ getTranslatedText(nombre_gains.text) }}</td>
                    <td>{{ renderPrice(nombre_gains.price) }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 4" class="about_item">
      <div class="about_item__head">
        {{ getTranslatedText("3 - Loto Turbo") }}
      </div>
      <div class="about_item__content" v-for="(lotoTurbo, index) in transformedData(this.dataTurbo)" :key="index">
        <div class="about_item__content__title">
          {{ getTranslatedText(lotoTurbo.title) }}
        </div>
        <div class="about_item__content__table">
          <div class="about_item__content__table__item">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">
                    {{ getTranslatedText("nombre de numéros gagnants") }}
                  </th>
                  <th scope="col">{{ getTranslatedText("Mise") }}</th>
                  <th scope="col">{{ getTranslatedText("Gains en GNF") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ getTranslatedText(lotoTurbo.nombre_gains[0].text) }}</td>
                  <td class="text-transform" :rowspan="lotoTurbo.nombre_gains.length">
                    {{ renderPrice(lotoTurbo.price) }} {{ getTranslatedText($store.state.userInfo.gamev3.currency_code)
                    }}
                  </td>
                  <td>
                    {{ renderPrice(lotoTurbo.nombre_gains[0].price) }}
                  </td>
                </tr>
                <template v-for="(nombre_gains, index) in lotoTurbo.nombre_gains">
                  <tr v-if="index" :key="index">
                    <td>{{ getTranslatedText(nombre_gains.text) }}</td>
                    <td>{{ renderPrice(nombre_gains.price) }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 5" class="about_item">
      <div class="about_item__head">
        {{ getTranslatedText("Loto 6/45") }}
      </div>
      <div class="about_item__content">
        <div class="about_item__content__table">
          <div class="about_item__content__table__item">
            <div class="about_item__content__table__item__title">
              {{ getTranslatedText('Répartition des Gains') }}
            </div>
            <table class="table table-winnings-645">
              <thead>
                <tr>
                  <th scope="col">
                    {{ getTranslatedText("Numéros corrects") }}
                  </th>
                  <th scope="col">{{ getTranslatedText("winning") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in loto645" :key="index">
                  <td>{{ item.numbersN }}</td>
                  <td>{{ item.winning }}</td>
                </tr>
              </tbody>
            </table>
            <i class="about_item__content__table__item__note">
              {{ getTranslatedText('*à partager entre le nombre de gagnants') }}
            </i>
          </div>
        </div>
      </div>
      <div class="about_item__content">
        <div class="about_item__content__table">
          <div class="about_item__content__table__item">
            <div class="about_item__content__table__item__title">
              {{ getTranslatedText('Nombre de combinaisons par type de Pari') }}
            </div>
            <table class="table table-combinations-645">
              <thead>
                <tr>
                  <th scope="col">
                    {{ getTranslatedText("Type de Pari") }}
                  </th>
                  <th scope="col">{{ getTranslatedText("Nbre numéros") }}</th>
                  <th scope="col">{{ getTranslatedText("Nbre combinaisons") }}</th>
                  <th scope="col">{{ getTranslatedText("Prix ticket") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in loto645Combinations" :key="index">
                  <td>{{ item.type }}</td>
                  <td>{{ item.numberN }}</td>
                  <td>{{ item.combinations }}</td>
                  <td>{{ renderPrice(item.price) }} {{ getTranslatedText($store.state.userInfo.gamev3.currency_code) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="about_item__content__table__item__notes">
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('Pari Simple') }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('Multiple7') }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('Multiple8') }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('Multiple9') }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('Multiple10') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 6" class="about_item">
      <div class="about_item__head">
        {{ getTranslatedText("loto 4/59") }}
      </div>
      <div class="about_item__content">
        <div class="about_item__content__table">
          <div class="about_item__content__table__item">
            <div class="about_item__content__table__item__title">
              {{ getTranslatedText('Répartition des Gains') }}
            </div>
            <table class="table table-winnings-459">
              <thead>
                <tr>
                  <th scope="col">
                    {{ getTranslatedText("Numéros corrects") }}
                  </th>
                  <th scope="col">{{ getTranslatedText("gains") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in loto459" :key="index">
                  <td>{{ item.numbersN }}</td>
                  <td>{{ item.gains }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 7" class="about_item">
      <div class="about_item__head">
        {{ getTranslatedText("loto 5/45") }}
      </div>
      <div class="about_item__content">
        <div class="about_item__content__table">
          <div class="about_item__content__table__item">
            <div class="about_item__content__table__item__title">
              {{ getTranslatedText('Répartition des Gains') }}
            </div>
            <table class="table table-winnings-545">
              <thead>
                <tr>
                  <th scope="col">
                    {{ getTranslatedText("Numéros corrects") }}
                  </th>
                  <th scope="col">{{ getTranslatedText("winning") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in loto545" :key="index">
                  <td>{{ item.numbersN }}</td>
                  <td>{{ item.winning }}</td>
                </tr>
              </tbody>
            </table>
            <i class="about_item__content__table__item__note">
              {{ getTranslatedText('*à partager entre le nombre de gagnants') }}
            </i>
          </div>
        </div>
      </div>
      <div class="about_item__content">
        <div class="about_item__content__table">
          <div class="about_item__content__table__item">
            <div class="about_item__content__table__item__title">
              {{ getTranslatedText('Nombre de combinaisons par type de Pari') }}
            </div>
            <table class="table table-combinations-545">
              <thead>
                <tr>
                  <th scope="col">
                    {{ getTranslatedText("Pari") }}
                  </th>
                  <th scope="col">{{ getTranslatedText("Nbre numéros sélectionné") }}</th>
                  <th scope="col">{{ getTranslatedText("Nbre combinaisons") }}</th>
                  <th scope="col">{{ getTranslatedText("Prix ticket") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in loto545Combinations" :key="index">
                  <td>{{ item.type }}</td>
                  <td>{{ item.numberN }}</td>
                  <td>{{ item.combinations }}</td>
                  <td>{{ item.price }} {{ getTranslatedText($store.state.userInfo.gamev3.currency_code) }}</td>
                </tr>
              </tbody>
            </table>
            <div class="about_item__content__table__item__notes">
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('Pari Simple 645')
                }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('Multiple6 645') }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('Multiple7 645') }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('Multiple8 645') }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('Multiple9 645') }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('Multiple10 645')
                }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 8" class="about_item">
      <div class="about_item__head_all_upper">
        {{ getTranslatedText("About games") }}
      </div>
      <div class="about_item__content ">
        <div class="about_item__content__table">
          <div class="about_item__content__table__item">
            <div class="about_item__content__table__item__title">
              {{ getTranslatedText('Cancellations rules') }}
            </div>
            <table class="table table-cancelation-rule">
              <tbody>
                <tr v-for="(item, index) in cancelationData" :key="index">
                  <td>{{ getTranslatedText(item.order) }}</td>
                  <td>{{ getTranslatedText(item.desc) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 9" class="about_item">
      <div class="about_item__head_all_upper">
        {{ getTranslatedText("Loto extra") }}
      </div>
      <div class="about_item__content ">
        <div class="about_item__content__table">
          <div class="about_item__content__table__item">
            <div class="about_item__content__table__item__title">
              {{ getTranslatedText('extra 6') }}
            </div>
            <div class="about_item__content__table__item__notes">
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('extra6-1') }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('extra6-2') }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('extra6-3') }}</span>
            </div>
            <br />
            <div class="about_item__content__table__item__title">
              {{ getTranslatedText('extra 7') }}
            </div>
            <div class="about_item__content__table__item__notes">
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('extra7-1') }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('extra7-2') }}</span>
              <span class="about_item__content__table__item__notes-text">{{ getTranslatedText('extra7-3') }}</span>
            </div>
            <br />
            <div class="about_item__content__table__item__title">
              {{ getTranslatedText('winnings grid') }}
            </div>
            <div class="about_item__content__table__item__table-container">
              <table class="table table-extra">
                <thead>
                  <tr>
                    <th scope="col">
                      {{ getTranslatedText("game") }}
                    </th>
                    <th scope="col">{{ getTranslatedText("picked numbers") }}</th>
                    <th scope="col">{{ getTranslatedText("stake") }}</th>
                    <th scope="col">{{ getTranslatedText("winnings match 2 numbers") }}</th>
                    <th scope="col">{{ getTranslatedText("winnings match 3 numbers") }}</th>
                    <th scope="col">{{ getTranslatedText("winnings match 4 numbers") }}</th>
                    <th scope="col">{{ getTranslatedText("winnings match 5 numbers") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in dataExtra" :key="index">
                    <td>{{ item.game }}</td>
                    <td>{{ item.pickedNumbers }}</td>
                    <td>{{ item.stake }}</td>
                    <td>{{ renderPrice(item.winningMatch2Number) }}</td>
                    <td>{{ renderPrice(item.winningMatch3Number) }}</td>
                    <td>{{ renderPrice(item.winningMatch4Number) }}</td>
                    <td>{{ renderPrice(item.winningMatch5Number) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="about_item__content__table__item__notes">
              <span class="about_item__content__table__item__notes-text">
                {{ getTranslatedText('Note') }}:
                {{ getTranslatedText('No win for 1 correct number') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 10" class="about_item">
      <ActivePromotion />
    </div>
    <div v-if="step === 11" class="about_item">
      <div class="about_item__head">
        {{ getTranslatedText(INSTANT_SLUG_GAME) }}
      </div>
      <div class="about_item__content">
        <div class="about_item__content__table">
          <div class="about_item__content__table__item">
            <div class="about_item__content__table__item__title">
              {{ getTranslatedText('Répartition des Gains') }}
            </div>
            <table class="table table-winnings-645">
              <thead>
                <tr>
                  <th scope="col">
                    {{ getTranslatedText("match") }}
                  </th>
                  <th scope="col">{{ getTranslatedText("prize") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in ABOUT_INSTANT_DATA_TABLE" :key="index">
                  <td>{{ item.match }}</td>
                  <td>{{ item.prize }}</td>
                </tr>
              </tbody>
            </table>
            <i class="about_item__content__table__item__note">
              {{ getTranslatedText('*à partager entre le nombre de gagnants') }}
            </i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import { formatPrice } from '@/utils/utils';

import ActivePromotion from "@/components/about/active-promotion.vue"
import { INSTANT_SLUG_GAME } from '@/constants/lotto';
import { ABOUT_INSTANT_DATA_TABLE } from '@/constants/abouts/keno';

export default {
  mixins: [languageMixins],
  components: { ActivePromotion },
  data() {
    return {
      INSTANT_SLUG_GAME,
      ABOUT_INSTANT_DATA_TABLE,
      step: 1,
      dataLotonap: [
        {
          title: "Nap 2: 2 numéros gagnants",
          nombre: [
            {
              text: "2 numéros",
            },
            {
              text: "1 numéro + 1 bonus",
            },
            {
              text: "0 numéro + 2 bonus",
            },
            {
              text: "1 numéro",
            },
            {
              text: "0 numéro + 1 bonus",
            },
          ],
          price: `2000 `,
          gains: [
            {
              price: "200000",
            },
            {
              price: "30000",
            },
            {
              price: "30000",
            },
            {
              price: "6000",
            },
            {
              price: "2000",
            },
          ],
        },
        {
          title: "Nap 3: 3 numéros gagnants",
          nombre: [
            {
              text: "3 numéros",
            },
            {
              text: "2 numéros + 1 bonus",
            },
            {
              text: "1 numéro + 2 bonus",
            },
            {
              text: "2 numéros",
            },
            {
              text: "1 numéro + 1 bonus",
            },
            {
              text: "0 numéro + 2 bonus",
            },
            {
              text: "1 numéro",
            },
          ],
          price: `2000 `,
          gains: [
            {
              price: "4000000",
            },
            {
              price: "200000",
            },
            {
              price: "120000",
            },
            {
              price: "80000",
            },
            {
              price: "4000",
            },
            {
              price: "4000",
            },
            {
              price: "2000",
            },
          ],
        },
        {
          title: "Nap 4: 4 numéros gagnants",
          nombre: [
            {
              text: "4 numéros",
            },
            {
              text: "3 numéros + 1 bonus",
            },
            {
              text: "2 numéros + 2 bonus",
            },
            {
              text: "3 numéros",
            },
            {
              text: "2 numéros + 1 bonus",
            },
            {
              text: "1 numéro + 2 bonus",
            },
            {
              text: "2 numéros",
            },
            {
              text: "1 numéro",
            },
          ],
          price: `2000 `,
          gains: [
            {
              price: "20000000",
            },
            {
              price: "2000000",
            },
            {
              price: "2000000",
            },
            {
              price: "400000",
            },
            {
              price: "200000",
            },
            {
              price: "100000",
            },
            {
              price: "40000",
            },
            {
              price: "2000",
            },
          ],
        },
        {
          title: "Nap 5: 5 numéros gagnants",
          nombre: [
            {
              text: "5 numéros",
            },
            {
              text: "4 numéros + 1 bonus",
            },
            {
              text: "3 numéros + 2 bonus",
            },
            {
              text: "4 numéros",
            },
            {
              text: "3 numéros + 1 bonus",
            },
            {
              text: "2 numéros + 2 bonus",
            },
            {
              text: "3 numéros",
            },
            {
              text: "2 numéros + 1 bonus",
            },
            {
              text: "2 numéros",
            },
            {
              text: "1 numéro",
            },
          ],
          price: `2000 `,
          gains: [
            {
              price: "200000000",
            },
            {
              price: "20000000",
            },
            {
              price: "20000000",
            },
            {
              price: "10000000",
            },
            {
              price: "1000000",
            },
            {
              price: "1000000",
            },
            {
              price: "200000",
            },
            {
              price: "30000",
            },
            {
              price: "24000",
            },
            {
              price: "2000",
            },
          ],
        },
      ],
      dataChance: [
        {
          title: "Chance 3: 3 numéros gagnants",
          nombre: [
            {
              text: "3 numéros",
            },
            {
              text: "2 numéros + 1 bonus",
            },
            {
              text: "1 numéro + 2 bonus",
            },
            {
              text: "2 numéros",
            },
            {
              text: "1 numéro + 1 bonus",
            },
            {
              text: "0 numéro + 2 bonus",
            },
          ],
          price: `2000 `,
          gains: [
            {
              price: "8200000",
            },
            {
              price: "160000",
            },
            {
              price: "160000",
            },
            {
              price: "80000",
            },
            {
              price: "4000",
            },
            {
              price: "4000",
            },
          ],
        },
        {
          title: "Chance 4: 4 numéros gagnants",
          nombre: [
            {
              text: "4 numéros",
            },
            {
              text: "3 numéros + 1 bonus",
            },
            {
              text: "2 numéros + 2 bonus",
            },
            {
              text: "3 numéros",
            },
            {
              text: "2 numéros + 1 bonus",
            },
            {
              text: "1 numéro + 2 bonus",
            },
            {
              text: "2 numéros",
            },
            {
              text: "1 numéro + 1 bonus",
            },
            {
              text: "0 numéro + 2 bonus",
            },
          ],
          price: `2000 `,
          gains: [
            {
              price: "40000000",
            },
            {
              price: "4000000",
            },
            {
              price: "4000000",
            },
            {
              price: "700000",
            },
            {
              price: "70000",
            },
            {
              price: "70000",
            },
            {
              price: "60000",
            },
            {
              price: "6000",
            },
            {
              price: "6000",
            },
          ],
        },
        {
          title: "Chance 5: 5 numéros gagnants",
          nombre: [
            {
              text: "5 numéros",
            },
            {
              text: "4 numéros + 1 bonus",
            },
            {
              text: "3 numéros + 2 bonus",
            },
            {
              text: "4 numéros",
            },
            {
              text: "3 numéros + 1 bonus",
            },
            {
              text: "2 numéros + 2 bonus",
            },
            {
              text: "3 numéros",
            },
            {
              text: "2 numéros + 1 bonus",
            },
            {
              text: "1 numéro + 2 bonus",
            },
            {
              text: "2 numéros",
            },
          ],
          price: `2000 `,
          gains: [
            {
              price: "400000000",
            },
            {
              price: "40000000",
            },
            {
              price: "40000000",
            },
            {
              price: "10000000",
            },
            {
              price: "1000000",
            },
            {
              price: "1000000",
            },
            {
              price: "400000",
            },
            {
              price: "40000",
            },
            {
              price: "40000",
            },
            {
              price: "30000",
            },
          ],
        },
      ],
      dataTurbo: [
        {
          title: "Turbo 2: 2 numéros gagnants",
          nombre: [
            {
              text: "2 numéros",
            },
            {
              text: "1 numéro + 1 bonus",
            },
            {
              text: "0 numéro + 2 bonus",
            },
          ],
          price: `2000 `,
          gains: [
            {
              price: "4800000",
            },
            {
              price: "80000",
            },
            {
              price: "80000",
            },
          ],
        },
        {
          title: "Turbo 3: 3 numéros gagnants",
          nombre: [
            {
              text: "2 numéros",
            },
            {
              text: "1 numéro + 1 bonus",
            },
            {
              text: "0 numéro + 2 bonus",
            },
          ],
          price: `2000 `,
          gains: [
            {
              price: "1600000",
            },
            {
              price: "60000",
            },
            {
              price: "60000",
            },
          ],
        },
        {
          title: "Turbo 4: 4 numéros gagnants",
          nombre: [
            {
              text: "2 numéros",
            },
            {
              text: "1 numéro + 1 bonus",
            },
            {
              text: "0 numéro + 2 bonus",
            },
          ],
          price: `2000 `,
          gains: [
            {
              price: "800000",
            },
            {
              price: "50000",
            },
            {
              price: "50000",
            },
          ],
        },
        {
          title: "Turbo 5: 5 numéros gagnants",
          nombre: [
            {
              text: "2 numéros",
            },
            {
              text: "1 numéro + 1 bonus",
            },
            {
              text: "0 numéro + 2 bonus",
            },
          ],
          price: `2000 `,
          gains: [
            {
              price: "500000",
            },
            {
              price: "24000",
            },
            {
              price: "24000",
            },
          ],
        },
      ],
      dataExtra: [
        {
          game: "Extra 6 - 2 Numéros",
          pickedNumbers: "2",
          stake: "2000",
          winningMatch2Number: "400000",
          winningMatch3Number: "",
          winningMatch4Number: "",
          winningMatch5Number: "",
        },
        {
          game: "Extra 6 - 3 Numéros",
          pickedNumbers: "3",
          stake: "2000",
          winningMatch2Number: "80000",
          winningMatch3Number: "3200000",
          winningMatch4Number: "",
          winningMatch5Number: "",
        },
        {
          game: "Extra 6 - 4 Numéros",
          pickedNumbers: "4",
          stake: "2000",
          winningMatch2Number: "40000",
          winningMatch3Number: "650000",
          winningMatch4Number: "21000000",
          winningMatch5Number: "",
        },
        {
          game: "Extra 6 - 5 Numéros",
          pickedNumbers: "5",
          stake: "2000",
          winningMatch2Number: "20000",
          winningMatch3Number: "230000",
          winningMatch4Number: "11000000",
          winningMatch5Number: "220000000",
        },
        {
          game: "Extra 7 - 2 Numéros",
          pickedNumbers: "2",
          stake: "2000",
          winningMatch2Number: "290000",
          winningMatch3Number: "",
          winningMatch4Number: "",
          winningMatch5Number: "",
        },
        {
          game: "Extra 7 - 3 Numéros",
          pickedNumbers: "3",
          stake: "2000",
          winningMatch2Number: "50000",
          winningMatch3Number: "2500000",
          winningMatch4Number: "",
          winningMatch5Number: "",
        },
        {
          game: "Extra 7 - 4 Numéros",
          pickedNumbers: "4",
          stake: "2000",
          winningMatch2Number: "30000",
          winningMatch3Number: "370000",
          winningMatch4Number: "17000000",
          winningMatch5Number: "",
        },
        {
          game: "Extra 7 - 5 Numéros",
          pickedNumbers: "5",
          stake: "2000",
          winningMatch2Number: "15000",
          winningMatch3Number: "80000",
          winningMatch4Number: "6000000",
          winningMatch5Number: "300000000",
        },
      ],
      loto645: [
        {
          numbersN: 6,
          winning: "Min. 1,000,000,000 GNF*",
        },
        {
          numbersN: 5,
          winning: "4,23% des mises participantes*",
        },
        {
          numbersN: 4,
          winning: "5,64% des mises participantes*",
        },
        {
          numbersN: 3,
          winning: "15,000 GNF par gagnant",
        }
      ],
      loto645Combinations: [
        {
          type: "Simple",
          numberN: "6",
          combinations: "1",
          price: "5000"
        },
        {
          type: "Multiple7",
          numberN: "7",
          combinations: "7",
          price: "35000"
        },
        {
          type: "Multiple8",
          numberN: "8",
          combinations: "28",
          price: "140000"
        },
        {
          type: "Multiple9",
          numberN: "9",
          combinations: "84",
          price: "420000"
        },
        {
          type: "Multiple10",
          numberN: "10",
          combinations: "210",
          price: "1050000"
        }
      ],
      loto459: [
        {
          numbersN: 4,
          gains: 'Minimun 270 000 000 GNF*'
        },
        {
          numbersN: 3,
          gains: '820 000 GNF par gagnant'
        },
        {
          numbersN: 2,
          gains: '55 000 GNF par gagnant'
        },
      ],
      loto545: [
        {
          numbersN: 5,
          winning: "Min. 1 000 000 000 GNF*",
        },
        {
          numbersN: 4,
          winning: "Min. 100 000 000 GNF*",
        },
        {
          numbersN: 3,
          winning: "40 000 GNF par gagnant",
        },
        {
          numbersN: 2,
          winning: "10 000 GNF par gagnant",
        }
      ],
      loto545Combinations: [
        {
          type: "Simple",
          numberN: "5",
          combinations: "1",
          price: "5 000"
        },
        {
          type: "Multiple",
          numberN: "6",
          combinations: "6",
          price: "30 000"
        },
        {
          type: "Multiple",
          numberN: "7",
          combinations: "21",
          price: "105 000"
        },
        {
          type: "Multiple",
          numberN: "8",
          combinations: "56",
          price: "280 000"
        },
        {
          type: "Multiple",
          numberN: "9",
          combinations: "126",
          price: "630 000"
        },
        {
          type: "Multiple",
          numberN: "10",
          combinations: "252",
          price: "1 260 000"
        }
      ],
      cancelationData: [
        {
          order: 1,
          desc: "you can only cancel a ticket if it is among the last 7 transactions.",
        },
        {
          order: 2,
          desc: "you have 3 minutes before the draw to cancel your tickets.",
        },
        {
          order: 3,
          desc: "you have 3 minutes after purchasing a ticket to cancel it. please note: rule number 2 also applies to tickets played at the last second.",
        }
      ],
    };
  },
  methods: {
    transformedData(data) {
      return data?.map((item) => {
        const nombre_gains = item.nombre.map((nombre, index) => {
          return {
            text: nombre.text,
            price: item.gains[index].price,
          };
        });
        return {
          title: item.title,
          price: item.price,
          nombre_gains: nombre_gains,

        };
      });
    },
    handleDetailPage(number) {
      this.step = number;
    },
    renderPrice(price) {
      return price == "" ? "" : formatPrice(price)
    }
  },
};
</script>
<style lang="scss">
@import "~ThemePath/scss/about/_index.scss";
</style>
